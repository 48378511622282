<template>
  <div id="app">
    <div v-if="showScannedResult" class="entered-location">
      <div class="close-button" @click="showScannedResult = false; cameraMode = 'auto';"></div>
      <div class="enter-title">你已進入場所</div>
      <div class="location">{{location}}</div>
      <div class="date">{{date}}</div>
      <img class="tick-img" src="./assets/tick.svg" alt="">
      <br/>
      <div class="checkbox-cell">
        <img :class="checkboxClass" @click="checkboxChecked = !checkboxChecked">
        4小時後自動離開
        <!-- <span class="checkbox-text">4小時後自動離開</span> -->
        <span class="checkbox-change">變更</span>
      </div>
      <button @click="showScannedResult = false; cameraMode = 'auto';">離開</button>
      <div class="leave-title">當你離開時請緊記按"離開"</div>
    </div>
    <!-- <canvas id="qr-canvas"></canvas> -->
    <div style="position: relative; height: 100vh; width: 100vw;">
      <!-- <div style="width: 100vw; height: 84px; background-color: #12b188;">
        <img src="./assets/back.svg" alt="" style="width: 24px; margin: 16px; position: absolute; left: 0; top: 32px;">
      </div> -->
      <qrcode-stream @decode="onDecode" :camera="cameraMode" style="width: 100vw; height: auto; position: absolute; top: 50%; -ms-transform: translateY(-50%); transform: translateY(-50%);"></qrcode-stream>
    </div>
  </div>
</template>

<script>
// import QRcode from 'qrcode-reader'
import {QrcodeStream} from "vue-qrcode-reader";

export default {
  
  name: 'moveOnSave',
  data: function () {
    return {
      cameraMode: 'auto',
      video: null,
      canvasElement: null,
      canvas: null,
      showScannedResult: false,
      checkboxChecked: false,
      scannedResult: ''
    }
  },
  components: {
    QrcodeStream
  },
  computed: {
    decodedResult: function () {
      if (this.scannedResult && this.scannedResult.split('eyJtZ')[1]) {
        return `eyJtZ${this.scannedResult.split('eyJtZ')[1]}`
      }
      return ''
    },
    location: function () {
      if (this.scannedResult && this.scannedResult.split('eyJtZ')[1]) {
        let resultString = decodeURIComponent(escape(atob(this.decodedResult)))
        let resultJson = JSON.parse(resultString)
        return resultJson.nameZh
      }
      return ''
    },
    date: function () {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = ('0' + date.getHours()).slice(-2);
      const minute = ('0' + date.getMinutes()).slice(-2);
      return `${year}-${month}-${day} ${hour}:${minute}`
    },
    checkboxClass: function () {
      if (this.checkboxChecked) {
        return 'checkbox checkbox-checked'
      }
      return 'checkbox checkbox-unchecked'
    }
  },
  methods: {
    onDecode: function (res) {
      console.log(res)
      if (res && res.split('eyJtZ')[1]) {
        this.cameraMode = 'off'
        this.scannedResult = res
        this.showScannedResult = true
      }
    }
  },
  /*mounted () {
    var qrcode = window.qrcode

    let _that = this
    this.$nextTick(function () {
      var video = document.createElement('video');
      var canvasElement = document.getElementById('qr-canvas');
      var canvas = canvasElement.getContext("2d");
      _that.video = video
      _that.canvasElement = canvasElement
      _that.canvas = canvas

      navigator.mediaDevices
      .getUserMedia({ video: { facingMode: 'environment' } })
      .then(function(stream) {
        canvasElement.hidden = false
        video.setAttribute('playsinline', true) // required to tell iOS safari we don't want fullscreen
        video.srcObject = stream
        video.play()
        tick()
        scan()
      })

      function tick() {
        canvasElement.width = video.videoWidth;
        canvasElement.height = video.videoHeight;
        canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height*(video.videoWidth/video.videoHeight));

        requestAnimationFrame(tick);
      }

      function scan() {
        console.log('scan')
        try {
          qrcode.decode()
        } catch (e) {
          setTimeout(scan, 300)
        }
      }

      qrcode.callback = (res) => {
        setTimeout(scan, 300)
        if (res && res.split('eyJtZ')[1]) {
          _that.scannedResult = res
          _that.showScannedResult = true
        }
      };
    })
  */
}
</script>

<style>
#app {
  position: absolute;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  text-align: center;
  color: white;
  background-color: black;
}

/* #qr-canvas {
  margin: auto;
  width: 100vw;
  height: 100vh;
} */

.entered-location {
  position: absolute;
  background-color:#12b188;
  width: 100vw;
  height: 100vh;
  z-index: 100;
}

.close-button {
  position: absolute;
  right: 4vw;
  top: calc(2vw + env(safe-area-inset-top));
  width: 24px;
  height: 24px;
}

.close-button:before, .close-button:after {
  position: absolute;
  left: 12px;
  content: ' ';
  height: 24px;
  width: 2px;
  background-color: white;
}
.close-button:before {
  transform: rotate(45deg);
}
.close-button:after {
  transform: rotate(-45deg);
}

.enter-title {
  font-size: 15px;
  margin-top: 12vh;
}

.location {
  font-size: 32px;
  margin-top: 8px;
  color: #fed426;
}

.date {
  font-size: 15px;
  margin-top: 8px;
}

.checkbox-cell {
  position: absolute;
  bottom: 18vh;
  left: 0;
  right: 0;
  margin: auto;
  padding-left: 24px;
  padding-right: 24px;
  line-height: 32px;
  text-align: left;
}

.checkbox {
  display: inline-block;
  vertical-align: top;
  position: relative;
  left: 0;
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.checkbox-unchecked {
  background-image: url('./assets/checkbox.svg');
}

.checkbox-checked {
  background-image: url('./assets/checkboxChecked.svg');
}

.checkbox-text {
  display: inline-block;
  position: relative;
}

.checkbox-change {
  display: inline-block;
  position: absolute;
  right: 24px;
}

button {
  position: absolute;
  bottom: 10vh;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #fed426;
  height: 50px;
  width: 80vw;
  border-radius: 23px;
  border: none;
  -moz-box-shadow:    -2px 2px 1px 0px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: -2px 2px 1px 0px rgba(0, 0, 0, 0.5);
  box-shadow: -2px 2px 1px 0px rgba(0, 0, 0, 0.5);
  font-size: 24px;
}

.leave-title {
  position: absolute;
  bottom: 6vh;
  left: 0;
  right: 0;
  font-size: 15px;
}

.tick-img {
  top: 6vh;
  width: 33vw;
  position: relative;
}
</style>
